import axios from 'axios';

const CryptoJS = require("crypto-js");


// encription functions
function generateSalt32Byte() {
    return CryptoJS.lib.WordArray.random(32);
}

function aesCbcPbkdf2EncryptToBase64(password, plaintext) {
    const passwordBytes = CryptoJS.enc.Utf8.parse(password);
    const salt = generateSalt32Byte();
    const PBKDF2_ITERATIONS = 15000;
    const encryptionKey = CryptoJS.PBKDF2(passwordBytes, salt, {
    keySize: 256 / 32,
    iterations: PBKDF2_ITERATIONS,
    hasher: CryptoJS.algo.SHA256,
    });
    const iv = CryptoJS.lib.WordArray.random(16);
    const ciphertext = CryptoJS.AES.encrypt(plaintext, encryptionKey, {
    iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
    });
    const ivBase64 = CryptoJS.enc.Base64.stringify(iv);
    const saltBase64 = CryptoJS.enc.Base64.stringify(salt);
    const ciphertextBase64 = ciphertext.toString();
    return `${saltBase64}:${ivBase64}:${ciphertextBase64}`;
}

function aesCbcPbkdf2DecryptFromBase64(password, ciphertextBase64) {
    const passwordBytes = CryptoJS.enc.Utf8.parse(password);
    const [saltBase64, ivBase64, ciphertext] = ciphertextBase64.split(':');
    const salt = CryptoJS.enc.Base64.parse(saltBase64);
    const iv = CryptoJS.enc.Base64.parse(ivBase64);
    const PBKDF2_ITERATIONS = 15000;
    const decryptionKey = CryptoJS.PBKDF2(passwordBytes, salt, {
    keySize: 256 / 32,
    iterations: PBKDF2_ITERATIONS,
    hasher: CryptoJS.algo.SHA256,
    });
    const decryptedtext = CryptoJS.AES.decrypt(ciphertext, decryptionKey, {
    iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
    });
    const decryptedtextP = decryptedtext.toString(CryptoJS.enc.Utf8);
    return decryptedtextP;
}

const url = 'https://kuzabusiness.invict.site/Api/web';

// 
export async function SalesData(sale_id){
    
    let sendingData = {
        code: 96,
        api: 100,
        data: {
            sale_id,
        }
    }
    
        
    const ciphertextBase64 = aesCbcPbkdf2EncryptToBase64("6789045129812345", JSON.stringify(sendingData))

    try {
        const res = await axios
            .post(url, 
                ciphertextBase64
            );
        let data = res.data;
        const decreptedData = aesCbcPbkdf2DecryptFromBase64("6789045129812345", data)
        return JSON.parse(decreptedData);
    } catch (e) {
        console.log(e);
    }
};