import React from 'react'
import './index.css'
import { useUsers } from '../hooks';

const TableSection = () => {

    const orderDetails = useUsers().sales_data.sale;

    const formatter = new Intl.NumberFormat('en-US', {
        useGrouping: true,
        minimumFractionDigits: 0
    });
    
    return (
        <div>
            <table>
                <tr>
                    <th style={{ borderTopLeftRadius: "20px" }}>Bidhaa</th>
                    <th>Idadi</th>
                    <th style={{ borderTopRightRadius: "20px" }}>Jumla <span style={{ fontWeight: "400", fontSize: "10px" }} >(TZS)</span></th>
                </tr>
                {
                    orderDetails.sale_products.map((product, index) => {
                        return (
                            <tr key={index}>
                                <td>{product.product.product_name}</td>
                                <td>{formatter.format(product.qty)}</td>
                                <td>Tshs {formatter.format(product.unit_price * product.qty)}</td>
                            </tr>
                        )
                    })
                }
                <tr>
                    <td>Punguzo</td>
                    <td></td>
                    <td>Tshs {formatter.format(orderDetails.discount)}</td>
                </tr>
                <tr>
                    <th style={{ borderBottomLeftRadius: "20px" }}>JUMLA</th>
                    <th></th>
                    <th style={{ borderBottomRightRadius: "20px" }}>Tshs {formatter.format(orderDetails.total)}</th>
                </tr>
            </table>
        </div>
    )
}

export default TableSection