import * as React from 'react'
import { ChakraProvider } from '@chakra-ui/react'
import Main from './components';
import { AppProvider } from './hooks';

function App() {
  return (
    <ChakraProvider>
      <AppProvider>
        <Main />
      </AppProvider>
    </ChakraProvider>
  )
}

export default App;
