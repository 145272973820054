import { Box, Flex, Text, Image } from '@chakra-ui/react'
import React from 'react'
import logo from '../assets/image.svg'

const Footer = () => {
    return (
        <Box mt='100px'>
            <Flex justifyContent='space-between'>
                <Image src={logo} alt="logo" />
                <Text
                    fontSize="12px"
                    fontWeight='500'
                >
                    Download kuzabusiness uachane na madaftari, usimamie biashara kidigitali
                </Text>
            </Flex>
        </Box>
    )
}

export default Footer