import { Box, Flex, Text } from '@chakra-ui/react'
import React from 'react'
import { useUsers } from '../hooks'

const Header = () => {

    const orderDetails = useUsers().sales_data.sale;

    const saleDate = (date) => {
        const inputDate = new Date(date);

        const options = { year: "numeric", month: "short", day: "numeric", };
        const formatter = new Intl.DateTimeFormat("en-US", options);

        const outputDateStr = formatter.format(inputDate);

        return outputDateStr;
    }
    
    return (
        <Flex justifyContent='space-between' mb='50px'>
            <SingleSection title={'KUTOKA'} description={orderDetails.user.business_name} value={`0${orderDetails.user.phone_number.slice(3,12)}`} />
            <SingleSection title={'ORDER'} description={`#${orderDetails.sale_number}`} value={orderDetails.registered ? saleDate(orderDetails.registered) : orderDetails.registered} />
            <SingleSection title={'MTEJA'} description={orderDetails.customer.customer_name} value={orderDetails.customer.customer_phone} />
        </Flex>
    )
}

export default Header


// single section
const SingleSection = ({title, description, value}) => {
    return (
        <Box>
            {/* title */}
            <Text
                fontSize='14px'
                fontWeight='600'
                mb='15px'
                textAlign='center'
            >
                {title}
            </Text>

            {/* description */}
            <Text
                fontSize='12px'
                fontWeight='400'
                mb='5px'
                textAlign='center'
            >
                {description}
            </Text>

            {/* value */}
            <Text
                fontSize='12px'
                fontWeight='400'
                textAlign='center'
            >
                {value}
            </Text>
        </Box>
    )
}