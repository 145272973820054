import React, { useCallback, useContext, useReducer } from "react";

// create a context
const UserContext = React.createContext();
const UpdateUserContent = React.createContext();

// export contexts
export function useUsers(){
    return useContext(UserContext);
}
export function useUpdateUsers() {
    return useContext(UpdateUserContent);
}


// 
export function AppProvider({ children }) {

    const [state, dispatch] = useReducer((state, action) => {
            switch (action.type) {
                case "SET_SALES_DATA":
                    return { ...state, sales_data: action.payload};
                default:
                    return state;
            }
        },
        {
            sales_data: {
                "code": 0,
                "sale": {
                    "sale_id": "",
                    "sale_products": [],
                    "customer": {
                        "customer_id": "",
                        "customer_name": "",
                        "customer_phone": "",
                        "registered": "",
                        "user": "",
                        "employee": null
                    },
                    "employee": null,
                    "payments": [],
                    "return_products": [],
                    "sale_number": "",
                    "total": 0,
                    "paid": 0,
                    "balance": 0,
                    "discount": 0,
                    "is_reconciled": false,
                    "comments": "",
                    "payment_channel": "",
                    "status": "",
                    "raw_sale": "",
                    "registered": "",
                    "user": {
                        "user_id": "",
                        "full_name": "",
                        "phone_number": "",
                        "email": "",
                        "country": "",
                        "password": "",
                        "firebase_token": "",
                        "is_new": true,
                        "business_name": "",
                        "is_primary": true,
                        "reference_id": "",
                        "location": "",
                        "business_nature": "",
                        "domain_name": "",
                        "domain_logo": "",
                        "whatsapp_number": "",
                        "is_domain_active": false,
                        "old_id": 0,
                        "subscription_end": "",
                        "is_profile": true,
                        "is_kuza_pro_active": true,
                        "last_login": "",
                        "kyc_status": "",
                        "online_shop_registered": null,
                        "registered": ""
                    }
                }
            },
        }
    )

    const updateFunctionData = useCallback((newData) => {
        dispatch(newData);
    }, []);

    return (
        <UserContext.Provider value={state}>
            <UpdateUserContent.Provider value={updateFunctionData} >

                {children}

            </UpdateUserContent.Provider>
        </UserContext.Provider>
    )
}