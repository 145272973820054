import { Box } from '@chakra-ui/react'
import React, { useCallback, useEffect } from 'react'
import Header from './header'
import TableSection from './table'
import { useUpdateUsers } from '../hooks'
import { SalesData } from '../network'
import Footer from './footer'

const Main = () => {

    const pathname = window.location.pathname;

    const updateSalesData = useUpdateUsers();

    const getSalesData = useCallback(() => {
        SalesData(pathname.slice(1))
            .then(data => {
                updateSalesData({type: "SET_SALES_DATA", payload: data})
            })
                .catch(error => {
                console.log(error);
            });
    }, []);

    useEffect(() => {
        getSalesData()
    }, []);

    return (
        <Box pt='50px' pr='15px' pl='15px' fontFamily='IBM Plex Sans'>

            {/* header */}
            <Header />

            {/* table */}
            <TableSection />

            {/* footer */}
            <Footer />
        </Box>
    )
}

export default Main